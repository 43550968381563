<template>
  <el-dialog width="1200px" :visible='showAddShopsDialog' center @close="closeAddGroupShops" append-to-body>
    <span slot='title' style="font-size:18px;font-weight:bold;">{{this.tradingAreaName||"未填写"}}</span>
    <div class="groupBuyingList">
      <div class="tableBox">
        <el-table :data="groupShopTableData" ref="multipleTable" style="width: 100%" :row-key="getRowKey" border
          @selection-change="selectRow" v-loading="loading">
          <el-table-column prop="isAdd" label="选择" align="center" type="selection" reserve-selection :selectable="selectAble">
          </el-table-column>
          <el-table-column prop="shopCode" label="商户号" align="center"></el-table-column>
          <el-table-column prop="shopName" label="商户名" align="center"></el-table-column>
          <el-table-column prop="contactTel" label="商户联系方式" align="center" width="150"></el-table-column>
          <el-table-column prop="createTime" label="添加商户时间" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.createTime">{{scope.row.createTime}}</span>
              <span v-else>{{"-"}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="amount" label="抽佣比例" align="center" width="100">
            <template slot-scope="scope">
              <span>{{scope.row.amount+"%"}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="status" label="状态" align="center" width="100">
            <template slot-scope="scope">
              <span v-if="scope.row.status==1">营业</span>
              <span v-if="scope.row.status==2">预订</span>
              <span v-if="scope.row.status==3">打烊</span>
              <span v-if="scope.row.status==4">停业</span>
              <span v-else-if="scope.row.status==''">-</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-pagination class="mt10" background :page-count="totalPage" :current-page.sync="currentPage" @current-change="pageChange">
      </el-pagination>
      <div slot='footer'>
        <div class="flexbox flex-lr flex-align-center mt20">
          <el-input v-model="form.shopName" placeholder="商户名称" style="width:200px;margin-left:20px;" clearable></el-input>
          <el-input v-model="form.contactTel" placeholder="商户电话" style="width:200px;margin-left:20px;" class="mr20" clearable></el-input>
          <el-button type="primary" @click="searchAddShop">筛选</el-button>
          <el-button type="info" @click="clear">清空</el-button>
          <el-button type="primary" @click="confirmAddButton">确认添加</el-button>
          <el-button type="info" @click="closeAddGroupShops">关闭</el-button>
        </div>
      </div>
    </div>
    <el-dialog :visible="confirmAddShopsDialog" center @close="confirmAddShopsDialog=false" append-to-body width="500px"
      class="confirmDialog">
      <img src="/img/dialog/exploreIcon.png" alt="">
      <p style="font-size:16px;" class="mt10">确认新增选择商户</p>
      <p class="mt10">新增后商户将根据用户距离进行排序展示</p>
      <span slot="footer" class="dialog-footer">
        <el-button @click="confirmAddShopsDialog = false">取消</el-button>
        <el-button type="primary" @click="confirmAddShop">确定</el-button>
      </span>
    </el-dialog>
  </el-dialog>
</template>
<script>
import {
  $sg_addTradingAreaShop,
  $sg_tradingAreaShopall,
} from '@/api/schoolGroup';

export default {
  data() {
    return {
      loading: true,
      shopIds: [], // 门店ID
      tradingAreaName: '', // 商圈名称
      tradingAreaId: '',
      showAddShopsDialog: false,
      confirmAddShopsDialog: false, // 确认添加弹窗
      form: {
        shopName: '',
        shopPhone: '',
      },
      totalPage: 1,
      currentPage: 1,
      groupShopTableData: [
      ],
    };
  },
  components: {
  },
  created() {
    // this.getTableData();
  },
  methods: {
    // 保留当前分页选中
    getRowKey(row) {
      return row.shopId;
    },
    selectAble(row) {
      if (row.isAdd === 1) {
        return false;
      } else {
        return true;
      }
    },
    //  选中商店时
    selectRow(selectedArr) {
      console.log(selectedArr);
      this.shopIds = selectedArr.map((v) => (v.shopId));
      console.log(this.shopIds, '选中的数组id');
    },

    //   打开弹窗
    openAddGroupShops() {
      this.showAddShopsDialog = true;
    },
    // 关闭弹窗
    closeAddGroupShops() {
      this.showAddShopsDialog = false;
    },
    // 确认添加弹窗
    confirmAddButton() {
      if (this.shopIds.length === 0) {
        this.$message.error('您未选中任何商户');
        return;
      }
      this.confirmAddShopsDialog = true;
    },
    // 确认添加商户
    confirmAddShop() {
      const params = {
        tradingAreaId: this.tradingAreaId,
        shopIds: this.shopIds,
      };
      $sg_addTradingAreaShop(params).then(() => {
        console.log('确认添加', this.tradingAreaId);
        this.confirmAddShopsDialog = false;
        this.$parent.$parent.getTableData(this.tradingAreaId);
        this.$refs.multipleTable.clearSelection();
        this.showAddShopsDialog = false;
      });
    },
    // 筛选
    searchAddShop() {
      this.getTableData(this.form);
      this.currentPage = 1;
    },
    // 清空
    clear() {
      this.form = {
        shopName: '',
        shopPhone: '',
      };
      this.getTableData();
      this.currentPage = 1;
    },
    // 请求列表数据
    getTableData() {
      const params = {
        tradingAreaId: this.tradingAreaId,
        ...this.form,
        pageCurrent: this.currentPage,
        pageSize: 15,
      };
      $sg_tradingAreaShopall(params).then((res) => {
        this.groupShopTableData = res.records;
        this.totalPage = res.pages;
        this.loading = false;
      });
      this.loading = true;
    },
    // 翻页
    pageChange() {
      this.getTableData(this.form);
    },
  },
};
</script>
<style lang="scss" scoped>
.confirmDialog {
  /deep/.el-dialog--center {
    .el-dialog__body {
      text-align: center;
    }
  }
}
.groupBuyingList {
  width: 100%;
  margin: 0;
  padding: 40px 20px;
  box-sizing: border-box;
  background-color: #fff;
  .tableBox {
    width: 100%;
    margin-top: 10px;
  }
  .el-table {
    & > th {
      background-color: #ef3f46;
      color: #fff;
    }
  }
  /deep/.el-checkbox__inner {
    border-radius: 50%;
  }
  .dialogBtn {
    width: 100%;
    margin-top: 60px;
    .btn {
      width: 200px;
      height: 50px;
      border-radius: 50px;
    }
  }
}
</style>

