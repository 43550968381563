<template>
  <div class="eatInOrder">
    <div class="timeChoiceDiv flexbox flex-lr flex-align-center">
      <div class="timeAndBtn flexbox flex-lr flex-align-center mr20">
        <el-input v-model="form.tradingAreaName" placeholder="商圈名称" style="width:160px;" clearable></el-input>
        <el-select v-model="form.principalId" placeholder="选择归属代理商" clearable filterable remote class="ml20 mr20" :loading="loading"
          :remote-method="searchAgent" @change="agentSidChange">
          <el-option v-for="item in agentList" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        <el-input v-model="form.agentTel" placeholder="归属代理商电话" style="width:160px;" class="mr20" clearable type="number"></el-input>
        <el-input v-model="form.principalName" placeholder="校园代理" style="width:160px;" clearable></el-input>
      </div>
      <el-button type="primary" class="sure" @click="search">筛选</el-button>
      <el-button type="info" class="sure" @click="clear">清空</el-button>
      <el-button type="primary" @click="addShops">新增商圈</el-button>

    </div>
    <div class="tableBox">
      <el-table :data="tableData" style="width: 100%" border>
        <el-table-column prop="tradingAreaName" label="商圈名称" align="center"></el-table-column>
        <el-table-column prop="agentName" label="归属代理商" align="center"></el-table-column>
        <el-table-column prop="agentTel" label="代理商电话" align="center"></el-table-column>
        <el-table-column prop="principalName" label="校园代理" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.principalName">{{scope.row.principalName}}</span>
            <span v-else>{{"-"}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="principalPhone" label="校园代理手机号" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.principalPhone">{{scope.row.principalPhone}}</span>
            <span v-else>{{"-"}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="serverTel" label="客服电话" align="center"></el-table-column>
        <el-table-column prop="orderType" label="商圈商户" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="tenant(scope.row)">详情</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="" align="center" label="操作">
          <template slot-scope="scope">
            <!-- <el-button type="text" @click="orderDetail(scope.row)">编辑</el-button>
            <el-button type="text" @click="orderDetail(scope.row)">删除</el-button> -->
            <el-button type="text" @click="addShops(scope.row.tradingAreaId,2)">编辑</el-button>
            <el-button type="text" @click="delect(scope.row.tradingAreaId)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination class="mt10" background :page-count="totalPage" :current-page.sync="currentPage" @current-change="pageChange">
    </el-pagination>
    <!-- 新增商圈 -->
    <el-dialog :title="agentAddOrEdit" :visible.sync="dialogFormVisible" width="750px" class='agentDialog'>
      <el-form ref="form" :model="formitem" label-width="150px" :rules="rules">
        <el-form-item label="商圈名字" prop="tradingAreaName">
          <el-input v-model="formitem.tradingAreaName" placeholder="输入商圈名字"></el-input>
        </el-form-item>
        <el-form-item label="商圈区域" label-width="150px" prop="checkAreas">
          <el-cascader v-model='formitem.checkAreas' style="width:400px" :options="options" :props="props" placeholder="选择省市区" collapse-tags
            clearable>
          </el-cascader>
        </el-form-item>
        <el-form-item label="商圈位置" prop="location">
          <el-input class="mr5" placeholder="详细地址" v-model="formitem.location" style="width:332px" disabled></el-input>
          <el-button type="primary" @click="locationDia">定位</el-button>
        </el-form-item>
        <el-form-item label="归属代理商" label-width="150px" prop="principalId">
          <el-select v-model="formitem.principalId" placeholder="请选择代理商" clearable filterable remote :loading="loading"
            :remote-method="searchAgent" @change="agentSidChange" style="width:400px" :disabled="disableds">
            <el-option v-for="item in agentList" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="校园代理" prop="principalName" label-width="150px">
          <el-input v-model="formitem.principalName" placeholder="输入校园代理名称"></el-input>
        </el-form-item>
        <el-form-item label="校园代理手机号码" prop="principalPhone">
          <el-input v-model="formitem.principalPhone" placeholder="输入校园代理手机号码" type="number"></el-input>
        </el-form-item>
        <el-form-item label="客服电话" prop="serverTel">
          <el-input v-model="formitem.serverTel" placeholder="输入校园代理客服电话"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="sureSubmit">确认{{differentiate==true?'编辑' :'新增'}}</el-button>
      </div>
    </el-dialog>
    <!-- 删除弹窗 -->
    <Dialog ref="delectDialog" dialogWidth="600px" style="height:600px;">
      <div class="dialogBtn flexbox flex-align-center flex-justify-center">
        <el-button class="btn" type="info" @click="$refs.delectDialog.showDialog = false">取消</el-button>
        <el-button class="btn" type="primary" @click="confirmDelect">确认删除</el-button>
      </div>
    </Dialog>
    <!-- 商圈商户 -->
    <BusinessCircleMerchants ref="tenants" :list="list"></BusinessCircleMerchants>
    <!-- 商圈位置 -->
    <!-- 定位 -->
    <el-dialog :title="dialoglocationtitle" :visible.sync="locationDialog" :show-close="false" width="1200px">
      <div>
        <el-input v-model="formitem.location" style="width:465px;margin-bottom:15px;margin-right:30px;" @change="addAddressChange"
          placeholder="请输入商圈详细地址">
        </el-input>
        <el-button @click="searchAddress" type="primary">搜索</el-button>
      </div>
      <div class="map-box">
        <div id="shopPointContainer" style="width:100%;height:549px;"></div>
      </div>
      <span slot="footer" class="dialog-footer flexbox flex-justify-center">
        <el-button type="primary" class="btn" @click="yeslocationDialog" style="width:100px;height:50px;">确认</el-button>
      </span>
    </el-dialog>

  </div>
</template>
<script>
// import {
//   $sp_findEatInOrder, $sp_agentList, $sp_spreadList,
// } from '@/api/order';
import {
  $sg_delTradingArea,
  $sg_tradingAreaPage,
  $sg_tradingAreaId,
  $sg_updTradingArea,
  $sg_addTradingArea,
  $sg_getTradingArea,
} from '@/api/schoolGroup';
import {
  $sp_areaTree,
} from '@/api/agentAll';
import Dialog from '@/components/common/dialog.vue';
import { AMapUtils } from '@base/utils';
import BusinessCircleMerchants from './businessCircleMerchants.vue';

export default {
  data() {
    return {
      disableds: false, // 编辑是不能填写
      list: '',
      checkAreasmerge: [],
      differentiate: false, // 区分是编辑还是新增
      tradingAreaId: '', // 删除id
      locationDialog: false, // 地图弹窗
      dialoglocationtitle: '商圈定位',
      dialoglocation: true, // 商圈位置弹出窗
      agentNameOptions: [], // 代理商名称数组
      options: [
      ],
      props: { multiple: true },
      //   areaState: true,
      agentAddOrEdit: '',
      formLabelWidth: '120px',
      dialogFormVisible: false,
      loading: false,
      formmap: { // 经纬度
        shopLat: '',
        shopLng: '',
      },
      form: {
        principalId: '', // 商圈归属id
        tradingAreaName: '', // 商圈名称
        agentTel: '', // 归属代理电话
        principalName: '', // 校园代理
      },
      formitem: {
        agentName: '',
        tradingAreaName: '', // 商圈名字
        checkAreas: [], // 商圈区域
        principalName: '', // 校园代理
        principalId: '', // 归属代理商  
        principalPhone: '', // 校园代理手机号码
        serverTel: '', // 客服电话
        location: '', // 商圈位置
      },

      rules: {
        tradingAreaName: [
          { required: true, message: '输入商圈名字', trigger: 'blur' },
        ],
        checkAreas: [
          { required: true, message: '选择省市区', trigger: 'blur' },
        ],
        principalId: [
          { required: true, message: '选择代理商', trigger: 'blur' },
        ],
        serverTel: [
          { required: true, message: '输入客服电话', trigger: 'blur' },
        ],
        location: [
          { required: true, message: '输入商圈位置', trigger: 'blur' },
        ],
      },
      time: '',
      totalPage: 1,
      currentPage: 1,
      tableData: [],
      branchOfficeList: [],
      agentList: [],
    };
  },
  components: {
    Dialog,
    BusinessCircleMerchants,
  },
  created() {
    this.getTableData();
  },
  mounted() {
    // this.getSpreadList();
    this.getAgentList();
    this.areaTree();
  },
  methods: {
    // 地图弹出框
    yeslocationDialog() {
      if (this.formmap.shopLat && this.formmap.shopLat) {
        this.locationDialog = false;
        console.log();
      } else {
        this.$message.error('详细地址有误！');
      }
    },
    // 商圈位置弹出窗
    locationDia() {
      const self = this;
      self.locationDialog = true;

      self.$nextTick(() => {
        self.initMap();
      });
    },

    // 地址改变
    addAddressChange() {
      this.formmap.shopLat = '';
      this.formmap.shopLng = '';
    },
    // 绘制地图
    initMap() {
      this.aMapUtils = new AMapUtils('shopPointContainer');
      this.aMapUtils.cityMap().then(() => {
        this.searchAddress();
        this.aMapUtils.mapAddEvent((map_lon, map_lat) => {
          this.aMapUtils.getAddress({ map_lon, map_lat }).then((res) => {
            this.formmap.shopLng = map_lat;
            this.formmap.shopLat = map_lon;
            this.$nextTick(() => {
              this.formitem.location = res;
              this.shopPosition = res;
              this.shopAddressList = [{
                location: {
                  lng: this.formmap.shopLng,
                  lat: this.formmap.shopLat,
                },
              }];
              this.resetMarker();
            });
          });
        });
      });
    },

    // 搜索地址
    searchAddress() {
      this.aMapUtils.search(this.formitem.location, '全国').then((res) => {
        if (res.pois.length) {
          this.formmap.shopLng = res.pois[0].location.lat;
          this.formmap.shopLat = res.pois[0].location.lng;
        }
        this.shopAddressList = res.pois;
        this.resetMarker();
      });
    },


    // 重置标记
    resetMarker() {
      if (this.markList && this.markList.length > 0) {
        this.aMapUtils.map.remove(this.markList);
      }
      this.markList = [];
      this.shopAddressList.forEach((address) => {
        this.markList.push(this.aMapUtils.addMaker([address.location.lng, address.location.lat]));
      });
      this.aMapUtils.map.setFitView(this.markList);
      console.log(this.form);
    },

    // 跳转商户商圈
    tenant(list) {
      this.list = list;
      this.$refs.tenants.dialogFormVisible = true;
      this.$refs.tenants.tradingAreaName = this.list.tradingAreaName;
      this.$refs.tenants.getTableData(this.list.tradingAreaId);
      // console.log(this.list.tradingAreaId, '9999999999999999999');
    },
    // 删除
    delect(id) {
      this.tradingAreaId = id;
      this.$refs.delectDialog.showDialog = true;
      this.$refs.delectDialog.dialogs = {
        icon: '/img/dialog/exploreIcon.png',
        title: '删除商圈',
        content: '删除商圈后，商圈将无法恢复，商圈数据将不进行保留。',
      };
    },
    // 确认删除
    confirmDelect() {
      // const params = {
      //   tradingAreaId: this.tradingAreaId,
      // };
      $sg_delTradingArea(this.tradingAreaId).then(() => {
        this.$refs.delectDialog.showDialog = false;
        this.getTableData();
      });
    },
    async checkForm() {
      await this.$validator({
        ...this.formitem,
      }, [
        'tradingAreaName/require/商圈名字',
        'checkAreas/require/商圈区域',
        'principalId/require/归属代理商',
        'location/require/商圈位置',
        'serverTel/require/客服电话',
      ]);
      const phoneRegular = /^\d{11}$/; // 手机号
      const addAgentRegular = /^.{1,16}$/; // 姓名
      const regPhone = /^[0-9-]*$/;
      // const regPhone = /^0(10|2[0-5789]|\\d{3})\\d{7,8}$/;
      if (!addAgentRegular.test(this.formitem.tradingAreaName)) {
        this.$message.error('商圈名字,1-16个字以内');
        return false;
      }
      if (!(this.formitem.checkAreas && this.formitem.checkAreas.length)) {
        this.$message.error('请选择商圈区域');
        return false;
      }
      if (!regPhone.test(this.formitem.serverTel)) {
        this.$message.error('请输入正确的客服手机号');
        return false;
      }
      if (this.formitem.principalPhone) {
        if (!phoneRegular.test(this.formitem.principalPhone)) {
          this.$message.error('请输入正确的代理商手机号');
          return false;
        }
      }



      return true;
    },
    // 确认新增
    async sureSubmit() {
      if (!await this.checkForm()) return;
      const params = {
        city: this.formitem.checkAreas[0][1], // 市
        district: this.formitem.checkAreas[0][2], // 区
        id: this.tradingAreaId, // 商圈id
        latitude: this.formmap.shopLng, // 纬度
        location: this.formitem.location, // 商圈位置
        longitude: this.formmap.shopLat, // 经度
        principalId: this.formitem.principalId, // 商圈归属id
        principalName: this.formitem.principalName, // 归属人名称
        principalPhone: this.formitem.principalPhone,
        province: this.formitem.checkAreas[0][0], // 省份
        serverTel: this.formitem.serverTel,
        tradingAreaName: this.formitem.tradingAreaName,
      };
      const upparams = {
        city: this.formitem.checkAreas[0][1], // 市
        district: this.formitem.checkAreas[0][2], // 区
        id: this.tradingAreaId, // 商圈id
        latitude: this.formmap.shopLng, // 纬度
        location: this.formitem.location, // 商圈位置
        longitude: this.formmap.shopLat, // 经度
        // principalId: this.formitem.principalId, // 商圈归属id
        principalName: this.formitem.principalName, // 归属人名称
        principalPhone: this.formitem.principalPhone,
        province: this.formitem.checkAreas[0][0], // 省份
        serverTel: this.formitem.serverTel,
        tradingAreaName: this.formitem.tradingAreaName,
      };
      if (this.differentiate) {
        $sg_updTradingArea(upparams).then(() => {
          this.dialogFormVisible = false;
          this.getTableData();
          this.differentiate = false;
        });
      } else {
        $sg_addTradingArea(params).then(() => {
          this.dialogFormVisible = false;
          this.getTableData();
        });
      }
      // const params = {
      //   city: this.formitem.checkAreas[0][1], // 市
      //   district: this.formitem.checkAreas[0][2], // 区
      //   id: this.tradingAreaId, // 商圈id
      //   latitude: this.formmap.shopLat, // 纬度
      //   location: this.formitem.location, // 商圈位置
      //   longitude: this.formmap.shopLng, // 经度
      //   principalId: this.formitem.principalId, // 商圈归属id
      //   principalName: this.formitem.principalName, // 归属人名称
      //   principalPhone: this.formitem.principalPhone,
      //   province: this.formitem.checkAreas[0][0], // 省份
      //   serverTel: this.formitem.serverTel,
      //   tradingAreaName: this.formitem.tradingAreaName,
      // };

      // this.dialogFormVisible = false;
    },
    // 获取代理商名称
    getAgentName(query) {
      // eslint-disable-next-line no-useless-return
      if (!query) return;
    },
    async recommendAgent(query) {
      this.loading = true;
      if (query) {
        await this.recommendAgentPage(query);
      } else {
        await this.recommendAgentPage('');
      }
      this.loading = false;
    },
    // 获取区域树状图
    areaTree() {
      $sp_areaTree().then((res) => {
        this.options = res.children;
      });
    },
    getCheckListByLastAreaId(areaIds) {
      const selectItems = [];
      this.options.forEach((country) => {
        if (!country.children) {
          console.log(country);
          return;
        }
        country.children.forEach((city) => {
          if (areaIds.includes(city.value)) {
            selectItems.push([country.value, city.value]);
          } else if (city.children) {
            city.children.forEach((dit) => {
              if (areaIds.includes(dit.value)) {
                selectItems.push([country.value, city.value, dit.value]);
              }
            });
          }
        });
      });
      console.log(selectItems, 'selectItems');
      return selectItems;
    },

    // 新增或编辑商圈
    addShops(id, index) {
      if (index === 2) {
        this.agentAddOrEdit = '编辑商圈';
        this.tradingAreaId = id;
        $sg_tradingAreaId(id).then((res) => {
          this.disableds = true;
          this.differentiate = true;
          this.dialogFormVisible = true;
          console.log(res, '666666666666666666666');
          this.formmap.shopLng = res.latitude; // 纬度
          this.formmap.shopLat = res.longitude; // 经度
          this.checkAreasmerge.push(res.province, res.city, res.district);
          this.formitem = { ...res, checkAreas: [this.checkAreasmerge] };
          this.formitem.principalId = res.agentName;
          console.log(this.formitem);
        });
      } else {
        this.agentAddOrEdit = '新增商圈';
        this.differentiate = false;
        this.dialogFormVisible = true;
        this.disableds = false;
        this.formitem = { // 让表单为空
          tradingAreaName: '', // 商圈名字
          checkAreas: [], // 商圈区域
          principalName: '', // 校园代理
          principalId: '', // 归属代理商  
          principalPhone: '', // 校园代理手机号码
          serverTel: '', // 客服电话
          location: '', // 商圈位置
        };
        this.formmap = { // 经纬度
          shopLat: '',
          shopLng: '',
        };
      }
      this.checkAreasmerge = [];
    },
    // 筛选
    search() {
      this.currentPage = 1;
      this.getTableData();
    },
    // 清空
    clear() {
      this.form = {
        principalId: '', // 商圈归属id
        tradingAreaName: '', // 商圈名称
        agentTel: '', // 归属代理电话
        principalName: '', // 校园代理
      };
      this.time = '';
      this.getTableData();
    },
    // 请求列表数据
    getTableData() {
      const params = {
        pageCurrent: this.currentPage,
        pageSize: 20,
        ...this.form,
      };
      // 请求订单数据
      $sg_tradingAreaPage(params).then((res) => {
        this.tableData = res.records;
        this.totalPage = res.pages;
      });
    },
    // 搜索归属分公司
    // async searchBranchOffice(query) {
    //   this.loading = true;
    //   if (query) {
    //     await this.getSpreadList(query);
    //   } else {
    //     await this.getSpreadList('');
    //   }
    //   this.loading = false;
    // },
    // 请求归属分公司数据
    // getSpreadList(platName = '') {
    //   const params = {
    //     platName,
    //   };
    //   this.branchOfficeList = [];
    //   return $sp_spreadList(params).then((res) => {
    //     const tempArr = [];
    //     res.forEach((item) => {
    //       const json = {
    //         value: item.spreadId,
    //         label: item.platName,
    //       };
    //       tempArr.push(json);
    //     });
    //     this.branchOfficeList = tempArr;
    //   });
    // },
    // 搜索代理商
    async searchAgent(query) {
      this.loading = true;
      if (query) {
        await this.getAgentList(query);
      } else {
        await this.getAgentList('');
      }
      this.loading = false;
    },
    // 请求代理商列表
    getAgentList(agentName = '') {
      const params = {
        // spreadId: this.form.spreadId,
        pageCurrent: this.currentPage,
        pageSize: 100,
        agentName,
      };
      this.agentList = [];
      return $sg_getTradingArea(params).then((res) => {
        const tempArr = [];
        res.records.forEach((item) => {
          const json = {
            value: item.agentSid,
            label: item.agentName,
          };
          tempArr.push(json);
        });
        this.agentList = tempArr;
      });
    },
    // 选择代理商
    agentSidChange() {
      this.getAgentList();
    },
    // 翻页
    pageChange() {
      this.getTableData();
    },
  },
};
</script>
<style lang="scss" scoped>
.dialogBtn {
  width: 100%;
  margin-top: 60px;
  .btn {
    width: 200px;
    height: 50px;
    border-radius: 50px;
  }
}
.agentDialog {
  font-weight: 900;
  .el-input {
    width: 400px;
  }
}
.eatInOrder {
  width: 100%;
  min-width: 1400px;
  height: 100%;
  margin: 0;
  padding: 40px 20px;
  box-sizing: border-box;
  background-color: #fff;
  .tableBox {
    width: 100%;
    margin-top: 40px;
  }
  .el-table {
    min-height: 580px;
    & > th {
      background-color: #ef3f46;
      color: #fff;
    }
  }
}
</style>

