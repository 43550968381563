<template>
  <el-dialog :visible.sync="dialogFormVisible" width=" 1590px" class="agentDialog">
    <div slot='title' style="" class="titlename">{{this.tradingAreaName}}</div>
    <div class="groupBuyingList">
      <div class="tableBox">
        <el-table ref="schoolGroupList" :row-key="getRowKey" :data="shopGroupTable" style="width: 100%;" border
          @selection-change="selectShops" v-loading="loading">
          <el-table-column prop="" label="选择" reserve-selection align="center" type="selection"></el-table-column>
          <el-table-column prop="shopCode" label="商户号" align="center"></el-table-column>
          <el-table-column prop="shopName" label="商户名" align="center"></el-table-column>
          <el-table-column prop="contactTel" label="商户联系方式" align="center" width="150"></el-table-column>
          <el-table-column prop="createTime" label="添加商户时间" align="center">
            <!-- <template slot-scope="scope">
            <span>{{scope.row.createTime}}</span>
          </template> -->
            <!-- <template slot-scope="scope">{{scope.row.saleCount || '-'}}</template> -->
          </el-table-column>
          <el-table-column prop="amount" label="抽佣比例" align="center" width="100">
            <template slot-scope="scope">
              <span>{{scope.row.amount+"%"}}</span>
            </template>

          </el-table-column>
          <!-- <el-table-column prop="shopProfitPrice" label="我的分润" align="center"></el-table-column> -->
          <el-table-column prop="categoryName" label="商户类型" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.categoryName">{{scope.row.categoryName}}</span>
              <span v-else>{{"-"}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="status" label="状态" align="center" width="100">
            <template slot-scope="scope">
              <span v-if="scope.row.status==1">营业</span>
              <span v-if="scope.row.status==2">预订</span>
              <span v-if="scope.row.status==3">打烊</span>
              <span v-if="scope.row.status==4">停业</span>
              <span v-else-if="scope.row.status==''">-</span>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="编辑" align="center" width="100">
            <template slot-scope="scope">
              <el-button type="text" @click="removeGroup(scope.row.id)">移除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-pagination class="mt10" background :page-count="totalPage" :current-page.sync="currentPage" @current-change="pageChange">
      </el-pagination>
      <!-- 设置广告弹窗 -->
      <!-- <el-dialog :visible='showSettingGroupBanner' width="800px" center @close="showSettingGroupBanner=false">
      <span slot="title" style="font-size:18px;font-weight:bold;">店铺列表商品广告位</span>
      <div class="settingGroupBannerContent">
        <p class="shopName">商户名：{{'咂舌米线'}}</p>
        <el-table :data="groupShopBannerTable" style="width: 100%;heigh:100%;" border>
          <el-table-column prop="" label="排序" width="50px" align="center" type="index" :index="indexMethod"></el-table-column>
          <el-table-column prop="goodsId" label="商品id" align="center" width="150px">
            <template slot-scope="scope">
              <el-select v-model="goodsIdOrName" filterable remote placeholder="输入商品Id"
                :remote-method="(query)=>getGroupShopGoods(query,scope.$index)">
                <el-option v-for="item in searchResultOptions" :key="item.shopId" :label="item.shopName" :value="item.shopId">
                </el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column prop="goodsName" label="商品名称" align="center" width="150px">
            <template slot-scope="scope">
              <el-select v-model="goodsIdOrName" filterable remote placeholder="输入商品名称"
                :remote-method="(query)=>getGroupShopGoods(query,scope.$index)">
                <el-option v-for="item in searchResultOptions" :key="item.shopId" :label="item.shopName" :value="item.shopId">
                </el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column prop="goodsImg" label="商品图片" align="center">
          </el-table-column>
          <el-table-column prop="goodsPrice" label="商品售价" align="center">
          </el-table-column>
          <el-table-column prop="name" label="操作" align="center">
            <template slot-scope="scope">
              <el-popconfirm @confirm='removeGroupBanner(scope.row)' confirm-button-text='是的' cancel-button-text='不了'
                icon="el-icon-warning-outline" icon-color="red" title="您确定删除吗？">
                <el-button slot="reference">移除</el-button>
              </el-popconfirm>
            </template>
          </el-table-column>
        </el-table>
        <p class="tips" style="margin-top:5px;">注：至少设置4个广告位，少于4个用户端不显示</p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showSettingGroupBanner = false">取 消</el-button>
        <el-button type="primary" @click="bannerSettingSubmit">保存并发布</el-button>
      </span>
    </el-dialog> -->
      <Dialog ref="delectDialog" dialogWidth="500px" :append="append">
              <div class="dialogBtn flexbox flex-align-center flex-justify-center" style="width:100%;margin-top:60px;">
                  <el-button class="btns" type="primary" @click="$refs.delectDialog.showDialog = false">返回</el-button>
                  <el-button class="btns" type="info" @click="confirmDelect">确认移除</el-button>
                </div>
         </Dialog>
      <AddShops ref="addShopDialog"></AddShops>
      <el-dialog :visible='showChangeClassification' center @close="showChangeClassification =false" width="500px" append-to-body>
        <span slot="title" style="font-size:18px;font-weight:bold;">修改商户分类</span>
        <div>
          <span>请选择商户分类</span>
          <el-select class="ml20" v-model="categoryId" placeholder="请选择分类">
            <el-option v-for="item in agentList" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="showChangeClassification = false">取消</el-button>
          <el-button type="primary" @click="shopClassificationSubmit">确定</el-button>
        </span>
      </el-dialog>
    </div>
    <div class="option">
      <el-button type="primary" @click="addShops">新增商户<i class="el-icon-circle-plus-outline"></i></el-button>
      <el-button type="primary" @click="openChangeShopClassification">批量修改商户分类</el-button>
      <!-- <el-checkbox v-model="a">备选项{{scope}}</el-checkbox> -->
    </div>
    <div class="tips">注：代理商需先配置商圈“首页布局”后，才能进行商户分类</div>
  </el-dialog>
</template>
<script>
import Dialog from '@/components/common/dialog.vue';
import {
  $sg_updateCategoryBatch,
  $sg_getCategory,
  $sg_tradingAreaShopdelete,
  $sg_tradingAreaShoppage,
} from '@/api/schoolGroup';
import AddShops from './components/AddShops.vue';

export default {
  data() {
    return {
      loading: true,
      categoryId: '', // 分类id
      tradingAreaShopIds: [], // 商圈商户主键ID
      tradingAreaName: '', // 商圈名称
      removeid: '', // 移除id
      append: true,
      removedialog: false, // 移除弹窗
      dialogFormVisible: false, // 弹窗开关
      shopsClassification: '', // 批量修改商户选择的商店分类
      showChangeClassification: false, // 批量修改商户状态弹窗
      groupShopId: '', // 要删除的商户号
      goodsIdOrName: '', // 设置广告位弹窗搜索所用的id
      searchResultOptions: [], // 查询的商品数据数组
      showSettingGroupBanner: false, // 设置店铺列表商品广告位弹窗
      time: '',
      totalPage: 1,
      currentPage: 1,
      shopGroupTable: [],
      selectedShopsArr: [], // 选中的商户数组
      agentList: [], // 商户分类的选项
    };
  },
  props: ['list'],
  components: {
    Dialog,
    AddShops,
  },
  // created() {
  //   this.getTableData(this.list.tradingAreaId);
  // },
  // updated() {
  //   this.getTableData(this.list.tradingAreaId);
  // },
  methods: {
    // 保留当前分页选中
    getRowKey(row) {
      return row.id;
    },
    // 打开批量修改商户分类弹窗
    openChangeShopClassification() {
      if (this.tradingAreaShopIds.length === 0) {
        this.$message.error('您未选中任何一个商户');
      } else {
        this.showChangeClassification = true;
        this.getAgentList();
      }
    },
    // 选中的商店
    selectShops(val) {
      console.log(val, '选中这条的所有数据');
      this.tradingAreaShopIds = val.map((v) => (v.id));
      console.log(this.tradingAreaShopIds, '选中的数组id');
    },
    // 批量确认修改商户分类
    shopClassificationSubmit() {
      // let 
      const index = this.agentList.findIndex((v) => v.value === this.categoryId);

      console.log(index, this.shopsClassification, this.categoryId, '选中的分类的下标');
      const params = {
        categoryId: this.categoryId, // 分类id 
        categoryName: this.agentList[index].label, // 分类名称
        tradingAreaId: this.list.tradingAreaId, // 商圈id
        ids: this.tradingAreaShopIds, // 商圈商户主键ID
      };
      $sg_updateCategoryBatch(params).then(() => {
        this.showChangeClassification = false;
        this.getTableData(this.list.tradingAreaId);
        this.$refs.schoolGroupList.clearSelection();
      });
    },
    // 请求列表数据
    getTableData(id) {
      const params = {
        tradingAreaId: id,
        contactTel: '', // 电话
        shopName: '', // 名称
        pageCurrent: this.currentPage,
        pageSize: 15,
      };
      $sg_tradingAreaShoppage(params).then((res) => {
        this.shopGroupTable = res.records;
        this.totalPage = res.pages;
        this.loading = false;
        // console.log(res.records[0].tradingAreaName, '5555555555555');
      });
      this.loading = true;
    },
    // 新增商户
    addShops() {
      this.$refs.addShopDialog.openAddGroupShops();
      this.$refs.addShopDialog.tradingAreaId = this.list.tradingAreaId;
      this.$refs.addShopDialog.tradingAreaName = this.tradingAreaName;
      this.$refs.addShopDialog.getTableData();
    },
    // 打开移除商户弹窗
    removeGroup(id) {
      console.log(id);
      this.removeid = id;
      // this.groupShopId = row.groupShopId;
      this.removedialog = true;
      this.$refs.delectDialog.showDialog = true;
      this.$refs.delectDialog.dialogs = {
        icon: '/img/dialog/exploreIcon.png',
        title: '商圈将移除此商户',
        content: '移除商户后，此商户将不在此商圈进行展示，如移除后，需要继续展示请在商圈添加此商户',
      };
    },
    // 确认移除商户
    confirmDelect() {
      const params = {
        id: this.removeid,
      };
      $sg_tradingAreaShopdelete(params).then(() => {
        this.$refs.delectDialog.showDialog = false;
        this.getTableData(this.list.tradingAreaId);
        this.$message.success('移除成功');
      }).catch((res) => {
        this.$message.error(res.msg);
      });
    },
    // 请求分类组件
    getAgentList() {
      const params = {
        tradingAreaId: this.list.tradingAreaId,
      };
      this.agentList = [];
      return $sg_getCategory(params).then((res) => {
        const tempArr = [];
        res.forEach((item) => {
          const json = {
            value: item.categoryId,
            label: item.categoryName,
          };
          tempArr.push(json);
        });
        this.agentList = tempArr;
        console.log(this.agentList);
      });
    },
    // 翻页
    pageChange() {
      this.getTableData(this.list.tradingAreaId);
    },
  },
};
</script>
<style lang="scss" scoped>
.tips {
  width: 100%;
  margin-top: 40px;
  font-size: 14px;
  color: #ef3f46;
}
.option {
  margin: 0px auto;
  width: 300px;
  height: 50px;
  align-content: center;
  display: flex;
  justify-content: space-between;
}
.titlename {
  font-size: 25px;
  font-weight: bold;
  text-align: center;
  width: 100%;
}
.groupBuyingList {
  width: 100%;
  margin: 0;
  padding: 40px 20px;
  box-sizing: border-box;
  background-color: #fff;
  .tips {
    width: 100%;
    margin-top: 40px;
    font-size: 14px;
    color: #ef3f46;
  }
  .tableBox {
    width: 100%;
    margin-top: 10px;
  }
  > .el-table {
    min-height: 580px;

    & > th {
      background-color: #ef3f46;
      color: #fff;
    }
  }
  /deep/.el-checkbox__inner {
    border-radius: 50%;
  }
  .btns {
    width: 200px;
    height: 50px;
    border-radius: 50px;
  }
}
</style>

